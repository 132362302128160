export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MAX_COMPANIONS = 9;

export const withoutNavigationRoutes = [
  { path: '/login' },
  { path: '/registration/details' },
  { path: '/registration/phone' },
  { path: '/registration/password' },
  { path: '/forgot-password' },
  { path: '/forgot-password/:token' },
  { path: '/verify-email' },
  { path: '/verify-email/:token' },
];

export const genderSelection = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Opt-out', label: 'Prefer not say' },
  { value: 'Non-Binary', label: 'Non-Binary' },
];

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const yearAndMonthMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const filtersMap = new Map([
  ['amenities', 'AmenityCode'],
  ['budget', 'RateRange'],
  ['cancellation', 'all'],
  ['class', 'Rating'],
  ['meals', 'all'],
  ['payment', 'all'],
  ['review', 'PropertyQualityCode'],
  ['type', 'PropertyTypeCode'],
  ['sortBy', 'SortBy'],
  ['sortOrder', 'SortOrder'],
]);

export const placeholderPopularCountries = [
  'United States',
  'United Kingdom',
  'France',
  'Germany',
  'Italy',
  'Spain',
  'Japan',
  'Australia',
  'Brazil',
  'Canada',
  'Mexico',
  'Turkey',
  'Saudi Arabia',
  'United Arab Emirates',
  'Argentina',
  'Austria',
  'Belgium',
  'Chile',
  'Colombia',
  'Czech Republic',
  'Denmark',
  'Egypt',
  'Finland',
  'Greece',
  'Hong Kong',
];

export const LoyaltyLevel = {
  BM: 'General Member',
  SM: 'Silver Member',
  GM: 'Gold Member',
  PM: 'Platinum Member',
  DM: 'Diamond Member',
};

export const hotelCodes = {
  RT: 'Accor Hotels',
  BY: 'Banyan Tree Hotels and Resorts',
  PU: 'Pullman Hotels',
  RX: 'Rixos Hotels',
  SB: 'Sofitel',
  OK: 'Alesia Distribution',
  AC: 'Atel Hotels Network',
  BW: 'Best Western Intl',
  UR: 'SureStay',
  CX: 'Country Inns By Carlson',
  PD: 'Park Inn',
  PK: 'Park Plaza Hotels And Resorts',
  QC: 'Quorvus Collection',
  RD: 'Radisson Hotels And Resorts',
  NZ: 'Ascend Collection',
  EZ: 'Cambria Suites',
  CC: 'Clarion Hotels',
  CI: 'Comfort Inn',
  CZ: 'Comfort Suites',
  EO: 'Econo Lodge',
  MZ: 'Mainstay Suites',
  QI: 'Quality Inns And Suites',
  RI: 'Rodeway Inn',
  SZ: 'Sleep Inn',
  UB: 'Suburban Hotels',
  CT: 'Cmnet',
  WX: 'Coast Hotels/Resorts',
  CG: 'City Lodge Hotels',
  FA: 'Fairmont Hotels and Resorts',
  YR: 'Raffles Hotels and Resorts',
  SL: 'Swissotel Hotels and Resorts',
  FG: 'FastBooking',
  GW: 'Great Hotels Of The World',
  FH: 'Fiesta Americana',
  YP: 'Gamma Hotels',
  MX: 'Motel 6',
  RM: 'Hetras',
  PY: 'Canopy by Hilton',
  CN: 'Conrad Hotels and Resorts',
  QQ: 'Curio Collection',
  DT: 'Doubletree Hotels',
  ES: 'Embassy Suites',
  HX: 'Hampton Inn',
  GI: 'Hilton Garden Inn',
  HH: 'Hilton Hotels',
  HL: 'HiltonHotels',
  HT: 'Home2Suites',
  HG: 'Homewood Suites By Hilton',
  OL: 'LXR',
  UP: 'Tapestry by Hilton',
  RU: 'Tru by Hilton',
  WA: 'Waldorf Astoria Htls and Rsrts',
  HQ: 'HRS',
  RH: 'ResHub',
  HY: 'Hyatt Hotels And Resorts',
  HU: 'Hyatt Vacations',
  AI: 'Armani Hotels',
  BE: 'Benchmark',
  DJ: 'Divan Hotels',
  FX: 'First Hotels',
  GQ: 'Genre Hotels',
  GF: 'Glbl Hsp Servs',
  GH: 'Grand Heritage',
  ZA: 'Hotel ZaZa',
  CA: 'Ilunion Hotels',
  KH: 'K Hotels',
  XO: 'Luxury Resorts',
  MP: 'Mantra Group',
  QG: 'Quest',
  SY: 'Starhotels',
  AT: 'The Address Hotels',
  LJ: 'The Lalit',
  TI: 'Thistle and Guoman Hotels',
  WV: 'TravelCLICK',
  LM: 'Vantis Hotel Group',
  VE: 'Vantis Hotels',
  WO: 'Wyndham Extra Holiday',
  AN: 'A N A Hotels',
  VA: 'Avid Hotels',
  YO: 'Candlewood Suites',
  CP: 'Crowne Plaza Hotels',
  VN: 'Even Hotels',
  HI: 'Holiday Inn',
  IN: 'Hotel Indigo',
  UL: 'Hualuxe Hotels and Resorts',
  IC: 'Intercontinental Hotels',
  KC: 'Kimpton Hotels',
  SP: 'Special Properties',
  YZ: 'Staybridge Suites',
  HA: 'Hotusa',
  KY: 'Keytel',
  LQ: 'La Quinta',
  LW: 'Leading Hotels Of The World',
  UE: 'Universal Hotels Orlando',
  GT: 'Golden Tulip Hotels',
  NN: 'Louvre Hotels',
  MS: 'Magnuson Hotels',
  MM: 'Maritim Hotels',
  AR: 'AC Hotels',
  AK: 'Autograph Collect',
  BG: 'Bulgari Hotels And Resorts',
  CY: 'Courtyard By Marriott',
  DE: 'Delta Hotels',
  EB: 'Edition',
  FN: 'Fairfield Inn',
  GE: 'Gaylord Entertainment',
  ET: 'Marriott Conference Center',
  EE: 'Marriott ExecuStay',
  MC: 'Marriott Hotels',
  VC: 'Marriott Vacation Club',
  OX: 'Moxy Hotels',
  PR: 'Protea Hotels',
  BR: 'Renaissance Hotels',
  RC: 'Residence Inn',
  RZ: 'Ritz Carlton Hotels',
  XV: 'Springhill Suites',
  TO: 'Towneplace Suites',
  EM: 'Master-Marriott',
  IT: 'Master-Two Roads',
  WR: 'Master-WYNDHAM',
  MV: 'MGM Resorts International',
  BA: 'Boscolo Hotels',
  GA: 'Global Hotel Alliance',
  LR: 'Leela Hotels and Resorts',
  LG: 'Lindner Hotels',
  GB: 'Macdonald Hotels Group',
  MH: 'Marco Polo Hotels',
  IQ: 'MyFidelio',
  SD: 'NEXT HOTELS',
  PF: 'Pan Pacific Hotels',
  PL: 'ParkRoyal Hotels',
  JD: 'The Doyle Collection',
  MU: 'Millennium/Copthorne Hotels',
  JH: 'Jolly Hotels',
  NS: 'N H Hotels',
  NK: 'Nikko Hotels International / Hotel JAL',
  AV: 'Allegiance Hosp',
  OM: 'Omni Hotels',
  HB: 'Omnibees',
  OI: 'Amadeus Linkhotel',
  ST: 'Xenia Hotellerie',
  OE: 'Belmond',
  LU: 'Digital Hotelier',
  DR: 'Drury Inns Inc',
  EA: 'Extended Stay America',
  CM: 'Hotel Camino Real',
  VY: 'Maybourne Hotel Group',
  UZ: 'Pegasus Connect',
  UI: 'Pegasus Connect Plus',
  SE: 'Sercotel Hotels',
  TP: 'Top International',
  HE: 'Historic Hotels',
  MN: 'Montage Hotels',
  NO: 'New Otani Hotels',
  BC: 'Preferred Hotels Affiliate Htl',
  PH: 'Preferred Hotels And Resorts',
  IH: 'Classic International Hotels',
  ON: 'Reconline',
  QO: 'Swiss Quality',
  TA: 'Reservhotel International',
  SH: 'Scandic Hotels',
  SC: 'Sceptre Hospitality Resources',
  PW: 'Whiteboard Labs',
  BP: 'Shilo Inns',
  TY: 'Idiso Hotel Distribution',
  ME: 'Melia Hotels Intl',
  SU: 'Southern Sun Hotels',
  AL: 'aloft Hotels',
  EL: 'Element Hotels',
  GX: 'Global Conextions',
  MD: 'Le Meridien',
  LC: 'Luxury Collection',
  SI: 'Sheraton Hotels',
  XR: 'The St Regis',
  TX: 'Tribute Portfolio',
  WH: 'W Hotels',
  WI: 'Westin Hotels And Resorts',
  BX: 'Columbus Reservation Service',
  SX: 'Supranational Hotels',
  LF: 'Alila Hotels and Resorts',
  AM: 'AMAN',
  BV: 'AmericasBestValue',
  QU: 'Aqua Htls and Rst',
  OA: 'Archer Hotels',
  CK: 'Arlo Hotels',
  AH: 'Aston Hotels and Resorts',
  AO: 'Atlantis',
  AU: 'Auberge Resorts',
  BI: 'Baccarat and 1 Hotels',
  LT: 'Brazil Hospitality Group',
  BT: 'Business Travel Advantage',
  EU: 'Chateaux and Hotels Collection',
  FF: 'Chic Collection',
  XE: 'City Express Hotels',
  CQ: 'Club Quarters',
  KL: 'Clubhouse Inns',
  CV: 'Como Hotels and Resorts',
  IA: 'Corinthia Hotels',
  NY: 'Denihan Hospitality Group',
  DS: 'Design Hotels',
  DN: 'Destination Hotels and Resorts',
  XL: 'Destino Xcaret Hotels and Resorts',
  DC: 'Dorchester Collection',
  DO: 'DorintResorts',
  DZ: 'Dream Hotels',
  DW: 'Dusit Hotels and Resorts',
  EQ: 'Eaton Hotels',
  EP: 'Epoque Hotels',
  EI: 'Executive Hotels And Resorts',
  FB: 'Fontainebleau Resorts',
  FS: 'Four Seasons Hotels',
  FI: 'Frasers Hospitality',
  GV: 'Gansevoort Hotel Group',
  GP: 'Graduate Hotels',
  GG: 'Grand Hotels NYC',
  GS: 'GrandStay Hospitality',
  ER: 'Hampshire Hotels',
  HR: 'Harrahs Casinos',
  HM: 'Hoteles Mision',
  HD: 'Hotelred',
  HO: 'HotelREZ Hotels and Resorts',
  IW: 'Hotels and Preference',
  IS: 'Ian Schrager',
  IR: 'Iberostar Hotels',
  BK: 'Interstate Htls/Rsrts',
  NI: 'INTRIGUE Hotels and Resorts',
  JA: 'JA Resorts and Hotels',
  JJ: 'Jin Jiang Hotels',
  JV: 'Joie De Vivre Hospitality',
  JR: 'JR Hotel Group',
  JT: 'Jumeirah',
  JI: 'Jurys Inns',
  KI: 'Kempinski Hotels',
  KO: 'Ksl Resorts',
  LE: 'L.E. Hotels',
  LO: 'Langham Hotels International',
  LB: 'Lebua Hotels and Resorts',
  LD: 'Leonardo Hotels',
  LP: 'Lexington Collection',
  LV: 'Life Hotel',
  LZ: 'Loews Hotels',
  MG: 'Magnolia Hotels',
  MO: 'Mandarin Oriental Hotels',
  GM: 'Meritus Hotels and Resorts',
  OH: 'Minor Hotels',
  MR: 'Morgans Hotel Group',
  MK: 'Movenpick Hotels and Resorts',
  NW: 'New World Hotels',
  NC: 'Noble House Hotels',
  NB: 'Nobu Hotels',
  NU: 'Northwood Hosp',
  OT: 'Oetker Collection',
  OC: 'Okura Hotels And Resorts',
  OO: 'One and Only',
  OR: 'Outrigger Hotels',
  PG: 'Park Hotel Group',
  PN: 'Peninsula  Hotels',
  PT: 'Pentahotels',
  PA: 'Principal Hayley Hotels',
  QT: 'QT',
  QR: 'Quality Reservations',
  RF: 'Red Roof Inns',
  RE: 'Regent International Hotels',
  WB: 'Relais And Chateaux',
  FC: 'Rocco Forte Htls',
  RW: 'Rosewood Hotels',
  RG: 'Rydges Hotel Group',
  PS: 'Sandman Hotels',
  SV: 'Sarova Hotels',
  SG: 'Shangri-La Hotels',
  GD: 'SiteMinder',
  SK: 'Sixty Hotels',
  LX: 'Small Luxury Htls of the World',
  SO: 'Sokos Hotels',
  SN: 'Sonesta Collection',
  AP: 'Standard International',
  SF: 'Sutton Place',
  YX: 'SynXis',
  TD: 'TFE Hotels',
  AZ: 'The Ascott Ltd',
  TM: 'Thompson Hotels',
  TF: 'Thon Hotels',
  IE: 'Tommie Hotels',
  TW: 'Trump Hotel Collection',
  VG: 'Viceroy Hotel Group',
  VI: 'Vienna House',
  VL: 'Virgin Hotels',
  WK: 'Warkwick Hotels and Resorts',
  UU: 'Worldhotels United',
  YL: 'Yotel',
  BN: 'Barcelo Hotels',
  YH: 'Booking Services by Trust',
  CB: 'Classic British Hotels',
  FL: 'Flemings Hotels',
  DD: 'Living Hotels',
  OB: 'Oberoi Hotels',
  RO: 'Rotana Hotels',
  SR: 'Steigenberger Hotel Group',
  TJ: 'Taj Hotels',
  TH: 'Trident Hotels',
  WW: 'Worldhotels',
  XT: 'Travel Tripper',
  TG: 'Travelodge Uk And Europe',
  DV: 'Village Hotels',
  RL: 'Red Lion Hotels',
  AA: 'Americinns',
  BU: 'Baymont Inns And Suites',
  DI: 'Days Inn',
  DX: 'Dolce Hotels and Resorts',
  FE: 'Fen Hotels',
  BH: 'Hawthorn Suites by Wyndham',
  HJ: 'Howard Johnson',
  KG: 'Knights Inn',
  MT: 'Microtel Inns and Suites',
  RA: 'Ramada Worldwide',
  TR: 'Registry Collection',
  OZ: 'Super 8',
  TQ: 'Trademark Collection',
  TL: 'Travelodge',
  WT: 'TRYP by Wyndham',
  WG: 'Wingate by Wyndham',
  PX: 'Wyndham Connections',
  WY: 'Wyndham Hotels and Resorts',
  QH: 'Qhotels',
  XN: 'XN Global Res',
};
