import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { UserGuard } from './guards/UserGuard';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Account } from './pages/Account';

import { NonAuthGuard } from './guards/NonAuthGuard';
import { ForgotPassword } from './pages/ForgotPassword';
import { VerifyEmail } from './pages/VerifyEmail';
import { Providers } from './components/Providers/Providers';
import { Results } from './pages/Results';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ResultDetails } from './pages/ResultDetails';
import { RegistrationDetails, RegistrationPassword, RegistrationPhone } from './pages/Registration';
import { APIProvider } from '@vis.gl/react-google-maps';
import 'react-phone-number-input/style.css';
import { motion } from 'framer-motion';
import { MyTrips } from './pages/MyTrips/MyTrips';
import { BookingManagement } from './pages/BookingManagement';
import { Loyalty } from './pages/Loyalty';
import { RedeemLoyalty } from './pages/RedeemLoyalty';
import { BookingReview } from './pages/BookingReview';
import { addCard } from 'credit-card-type';
import { useEffect } from 'react';
import { Legal, PrivacyPolicyDocument, TermsAndConditionsDocument } from './pages/Legal';
import { BookingHandler } from './pages/BookingHandler/BookingHandler';
import { NotFound } from './pages/NotFound';
import { Scripts } from './components/Scripts';
import { ContactUs } from './pages/ContactUs';
import { Promotion } from './pages/Promotion';
import { PromotionTerms } from './pages/PromotionTerms';

function App() {
  const location = useLocation();

  useEffect(() => {
    addCard({
      niceType: 'UATP',
      type: 'uatp',
      patterns: [1],
      gaps: [4, 9, 15],
      lengths: [15, 18],
      code: {
        name: 'CVV',
        size: 3,
      },
    });
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return (
    <APIProvider apiKey={import.meta.env.VITE_APP_GOOGLE_KEY}>
      <Providers>
        <Routes>
          <Route
            path="/"
            element={
              <UserGuard>
                <motion.div
                  key={location.pathname}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Outlet />
                </motion.div>
              </UserGuard>
            }
          >
            <Route path="account" element={<Account />} />
            <Route path="my-trips" element={<MyTrips />} />
            <Route path="loyalty" element={<Loyalty />} />
            <Route path="booking/:id/review/:uuid" element={<BookingReview />} />
            <Route path="loyalty/redeem" element={<RedeemLoyalty />} />
            <Route path="registration">
              <Route path="details" element={<RegistrationDetails />} />
              <Route path="phone" element={<RegistrationPhone />} />
              <Route path="password" element={<RegistrationPassword />} />
            </Route>
          </Route>
          <Route
            path="/"
            element={
              <NonAuthGuard>
                <motion.div
                  key={location.pathname}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Outlet />
                </motion.div>
              </NonAuthGuard>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify-email">
              <Route index element={<VerifyEmail />} />
              <Route path=":token" element={<VerifyEmail />} />
            </Route>
            <Route path="my-trips/:id/:uuid" element={<BookingManagement />} />
            <Route path="forgot-password/:token" element={<ForgotPassword />} />
            <Route path="results" element={<Results />} />
            <Route path="details/rate" element={<ResultDetails />} />
            <Route path="booking/payment" element={<BookingHandler />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="promoRegistration" element={<Promotion />} />
            <Route path="promoTC" element={<PromotionTerms />} />
            <Route
              path="legal"
              element={
                <motion.div
                  key={location.pathname}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Legal>
                    <Outlet />
                  </Legal>
                </motion.div>
              }
            >
              <Route index element={<TermsAndConditionsDocument />} />
              <Route path="privacy-policy" element={<PrivacyPolicyDocument />} />
              <Route path="terms-and-conditions" element={<TermsAndConditionsDocument />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>

        <ReactQueryDevtools initialIsOpen={false} />
        <Scripts />
      </Providers>
    </APIProvider>
  );
}

export default App;
