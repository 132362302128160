import { Button } from '../Button';

interface CallToActionProps {
  image?: string;
  title?: string;
  text?: React.ReactNode;
  action?: () => void;
  actionText?: string;
}
const defaultTitle = 'The great getaway, your way';
const defaultText = 'Save at least 15% on stays worldwide, from relaxing retreats to off-the-grid adventures';

export const CallToAction = ({ action, image, text, title, actionText }: CallToActionProps) => {
  return (
    <div className="bg-dorrus-background-secondary rounded-2xl overflow-hidden md:min-h-[350px] print:hidden">
      <div className="grid grid-cols-1 md:grid-cols-12 md:min-h-[350px]">
        <div className="col-span-6 relative h-[250px] md:h-auto">
          <img
            src={image ? image : '/images/cta-dashboard.jpeg'}
            alt="cal to action"
            className="absolute object-cover object-center h-full w-full rounded-xl"
          />
        </div>
        <div className="col-span-6 text-white p-6 flex flex-col items-center justify-center">
          <div className="max-w-full md:max-w-[80%] space-y-6">
            <h5 className="font-bold text-4xl md:text-5xl">{title ?? defaultTitle}</h5>
            <div style={{ whiteSpace: 'pre-line' }}>{text ?? defaultText}</div>
            <Button kind="tertiary" onClick={action} className="text-dorrus-background-secondary">
              {actionText ?? 'See more'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
