import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';

export const RegisterNowBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-dorrus-background-secondary text-white p-10 rounded-2xl relative overflow-hidden md:h-[220px]">
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-5 space-y-4">
          <h5 className="text-2xl font-bold">Register now and get more discounts</h5>
          <p>Our members get extra discounts.</p>
          <div className="flex gap-4">
            <Button onClick={() => navigate('/login')} kind="secondary" className="!w-fit bg-dorrus-button-border">
              Log in
            </Button>
            <Button onClick={() => navigate('/login')} kind="tertiary" className="text-dorrus-background-secondary">
              Join
            </Button>
          </div>
        </div>
        <div className="col-span-7 hidden md:block">
          <img className="absolute right-0 bottom-0 h-32 w-40" src="/images/image-1.png" alt="banner random place" />
          <img className="absolute right-36 top-0 h-20 w-36" src="/images/image-2.png" alt="banner random place" />
          <img className="absolute right-60 bottom-0 h-28 w-40" src="/images/image-3.png" alt="banner random place" />
          <img className="absolute right-96 top-0 h-28 w-28" src="/images/image-4.png" alt="banner random place" />
        </div>
      </div>
    </div>
  );
};
