import DorrusFullLogo from '@app/assets/dorrus-logo.svg?react';
import DorrusMinLogo from '@app/assets/dorrus-min-logo.svg?react';
import MenuIcon from '@app/assets/icons/menu.svg?react';
import US from '@app/assets/countries/US.svg';
import User from '@app/assets/icons/user.svg';
import ProfileIcon from '@app/assets/icons/profile.svg?react';
import ReservationIcon from '@app/assets/icons/building.svg?react';
import LoyaltyIcon from '@app/assets/icons/loyalty.svg?react';
import SignOutIcon from '@app/assets/icons/logout.svg?react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@app/context/user';
import { Button } from '../Button';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Loading } from '../Loading/Loading';
import { useState } from 'react';
import { BaseModal } from '../BaseModal';
import { HookedForm } from '../HookedForm';
import { SwitchField } from '../Fields';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTravellers } from '@app/context/travellers';
import { useBooking } from '@app/context/booking';
import { useSearch } from '@app/context/search';
import { levelColors } from '@app/utils/maps';

export const Navigation = () => {
  const navigate = useNavigate();
  const { travellersDispatch } = useTravellers();
  const { bookingsDispatch } = useBooking();
  const { searchDispatch } = useSearch();
  const {
    isAuthenticated,
    user,
    language,
    handleLogout,
    fetchingUserData,
    userDispatch,
    loyaltyLevels,
    getPriceFormat,
  } = useUser();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const formHook = useForm({ defaultValues: { unitOfMeasure: language.unitOfMeasure } });
  const loyaltyLevel = loyaltyLevels.find((level) => level.levelCode === user.programLevelCode);
  const openMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen);
  };

  const handleClick = (link: string) => {
    openMobileMenu();
    navigate(link);
  };

  const logout = () => {
    handleLogout();
    travellersDispatch({ type: 'logout' });
    bookingsDispatch({ type: 'logout' });
    searchDispatch({ type: 'logout' });
  };

  const handleLocationSubmit = (values: FieldValues) => {
    userDispatch({ type: 'setUnitOfMeasure', unitOfMeasure: values.unitOfMeasure ? 'KM' : 'MI' });
    toast.success('Location preferences updated successfully');
    handleLocationModal();
  };

  return (
    <>
      <nav className="container mx-auto flex justify-between items-center mt-6 px-0 md:px-4 mb-8">
        <Link to="/">
          <DorrusFullLogo className="hidden md:block" />
          <DorrusMinLogo className="block md:hidden z-[999] relative" />
        </Link>
        <div className="hidden md:flex gap-4 items-center">
          <button onClick={handleLocationModal} className="rounded-full overflow-hidden w-8 h-8 flex">
            <img src={US} className="object-cover" alt="en-US language" />
          </button>

          {fetchingUserData && (
            <div className="flex gap-2">
              <Loading width={30} height={30} />
            </div>
          )}

          {isAuthenticated && (
            <Menu>
              {({ open }) => (
                <>
                  <MenuButton>
                    <div className="bg-white rounded-full flex gap-4 pl-6 pr-4 py-1">
                      <div className="flex flex-col items-start">
                        <span className="font-medium text-lg">
                          {user.firstName} {user.middleName ? user.middleName.substring(0, 1) : ''} {user.lastName}
                        </span>
                        <span className="text-dorrus-text-secondary flex gap-1 text-xs justify-between w-full">
                          {loyaltyLevel && (
                            <span
                              className="uppercase font-semibold text-left"
                              style={{ color: levelColors[loyaltyLevel?.levelCode as keyof typeof levelColors] }}
                            >
                              {loyaltyLevel?.displayName}
                            </span>
                          )}
                          <span className="text-right">
                            {getPriceFormat(user?.balance?.redeemAvailPoints ?? 0)} points
                          </span>
                        </span>
                      </div>
                      <img src={User} alt="User profile" />
                    </div>
                  </MenuButton>
                  <AnimatePresence>
                    {open && (
                      <MenuItems
                        portal
                        as={motion.div}
                        anchor="bottom"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        className="z-[999] mt-2 sticky min-w-[270px] bg-white p-4 rounded-lg shadow-md flex flex-col gap-4 origin-top"
                      >
                        <MenuItem as="div" className="flex gap-2">
                          <ProfileIcon width={24} height={24} />
                          <Link to="/account">My Dorrus</Link>
                        </MenuItem>
                        <MenuItem as="div" className="flex gap-2">
                          <ReservationIcon width={24} height={24} />
                          <Link to="/my-trips">Trips</Link>
                        </MenuItem>
                        <MenuItem as="div" className="flex gap-2">
                          <LoyaltyIcon width={24} height={24} /> <Link to="/loyalty">Dorrus Beyond</Link>
                        </MenuItem>
                        <MenuItem
                          as="div"
                          className="cursor-pointer flex gap-2"
                          onClick={() => {
                            logout();
                            navigate('/');
                          }}
                        >
                          <SignOutIcon width={24} height={24} />
                          <span>Sign out</span>
                        </MenuItem>
                      </MenuItems>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Menu>
          )}

          {!isAuthenticated && !fetchingUserData && (
            <div className="flex gap-2">
              <Button onClick={() => navigate('/login')} kind="secondary" className="min-w-fit">
                Log in
              </Button>
              <Button onClick={() => navigate('/login')} className="border-0 bg-white min-w-fit font-semibold">
                Join
              </Button>
            </div>
          )}
        </div>
        <div className="flex md:hidden">
          <button
            type="button"
            className="bg-white rounded-full flex gap-4 pl-4 pr-4 py-1 items-center z-[999]"
            onClick={openMobileMenu}
          >
            {isAuthenticated && (
              <div className="flex flex-col">
                <span className="font-medium">
                  {user.firstName} {user.middleName ? user.middleName.substring(0, 1) : ''} {user.lastName}
                </span>
                <span className="text-dorrus-text-secondary flex gap-1 text-xs">
                  {loyaltyLevel && (
                    <span
                      className="uppercase font-semibold"
                      style={{ color: levelColors[loyaltyLevel?.levelCode as keyof typeof levelColors] }}
                    >
                      {loyaltyLevel?.displayName}
                    </span>
                  )}
                  <span className="mr-2">{getPriceFormat(user?.balance?.redeemAvailPoints ?? 0)} points</span>
                </span>
              </div>
            )}
            <MenuIcon />
          </button>
        </div>
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="fixed inset-0 bg-white z-[980] h-screen w-svw"
            >
              {isAuthenticated ? (
                <div className="container-md mx-auto h-full flex gap-6 flex-col p-6 pt-28 text-xl font-bold">
                  <button className="flex gap-2 items-center" onClick={() => handleClick('/account')}>
                    <ProfileIcon width={30} height={30} />
                    My Dorrus
                  </button>
                  <button className="flex gap-2 items-center" onClick={() => handleClick('/my-trips')}>
                    <ReservationIcon width={30} height={30} />
                    Trips
                  </button>
                  <button className="flex gap-2 items-center" onClick={() => handleClick('/loyalty')}>
                    <LoyaltyIcon width={30} height={30} /> Dorrus Beyond
                  </button>
                  <button
                    className="cursor-pointer flex gap-2 items-center"
                    onClick={() => {
                      logout();
                      handleClick('/');
                    }}
                  >
                    <SignOutIcon width={30} height={30} />
                    <span>Sign out</span>
                  </button>
                </div>
              ) : (
                <div className="container-md mx-auto h-full flex gap-6 flex-col p-6 pt-28 text-xl font-bold">
                  <div className="flex flex-col gap-2">
                    <Button onClick={() => navigate('/login')} kind="secondary" className="min-w-fit">
                      Log in
                    </Button>
                    <Button onClick={() => navigate('/login')} className="border-0 bg-white min-w-fit font-semibold">
                      Join
                    </Button>
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
      <BaseModal open={isLocationModalOpen} header="Location Preferences" onClose={handleLocationModal}>
        <HookedForm formHook={formHook} onSubmit={handleLocationSubmit} className="space-y-4">
          <div className="flex justify-between">
            <p>Units of Measure</p>
            <div className="flex gap-1">
              MI
              <SwitchField name="unitOfMeasure" /> KM
            </div>
          </div>

          <div className="flex gap-4">
            <Button onClick={handleLocationModal} className="border-0">
              Cancel
            </Button>
            <Button type="submit" kind="secondary">
              Save
            </Button>
          </div>
        </HookedForm>
      </BaseModal>
    </>
  );
};
