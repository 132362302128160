import type { ReactNode } from 'react';
import { useEffect, useReducer } from 'react';

import { userReducer } from './reducers';
import { UserStateContext, initialValues } from './utils';
import { getUser, getUserCards } from '@app/services/user';
import { useSearchParams } from 'react-router-dom';
import { localStorageHandler } from '@app/utils/utils';
import { fetchExternalLoyalties } from '@app/services/externalLoyalties';
import { getLoyaltyLevels } from '@app/services/loyalty';

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, dispatch] = useReducer(userReducer, initialValues);
  const {
    user,
    fetchingUserData,
    isAuthenticated,
    language,
    cards,
    fetchingUserCards,
    externalLoyalties,
    fetchingUserLoyalties,
    loyaltyLevels,
    fetchingLoyaltyLevels,
  } = state;

  const getPriceFormat = (number: number | string, isCurrency?: boolean, currencyCode?: string) => {
    return new Intl.NumberFormat(language?.language ?? 'en-US', {
      style: isCurrency ? 'currency' : 'decimal',
      ...(isCurrency && { currency: currencyCode ?? language?.currency ?? 'USD', currencyDisplay: 'narrowSymbol' }),
    }).format(Number(number ?? 0));
  };

  const handleLogout = () => {
    localStorage.removeItem('dtoken');
    localStorage.removeItem('email');
    localStorage.removeItem('dCurrentHotel');
    localStorage.removeItem('dSearchParams');
    localStorage.removeItem('dCurrentBooking');
    dispatch({ type: 'logout' });
  };

  useEffect(() => {
    const hasToken = searchParams.get('token');
    if (hasToken) {
      localStorage.setItem('dtoken', searchParams.get('token') ?? '');
      dispatch({ type: 'setFetching', fetching: true });
      setSearchParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const fetchUserInformation = async () => {
    const token = localStorageHandler.get('dtoken');

    if (!token) {
      dispatch({ type: 'setFetching', fetching: false });
      return;
    }

    await getUser()
      .then((res) => {
        dispatch({ type: 'setData', user: res.data });
      })
      .catch((err: Error) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: 'setFetching', fetching: false });
      });
  };

  useEffect(() => {
    if (fetchingUserData) {
      fetchUserInformation();
    }

    if (user && user?.email) {
      dispatch({ type: 'setFetchingCards', fetching: true });
      dispatch({ type: 'setFetchingLoyalties', fetching: true });
      dispatch({ type: 'setAuthenticated', authenticated: true });
      dispatch({ type: 'setFetchingLoyaltyLevels', fetching: true });
    } else {
      dispatch({ type: 'setAuthenticated', authenticated: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchingUserData]);

  useEffect(() => {
    if (user && fetchingUserCards) {
      getUserCards()
        .then((c) => {
          dispatch({ type: 'setCards', cards: c.data.cards });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          dispatch({ type: 'setFetchingCards', fetching: false });
        });
    }
  }, [user, fetchingUserCards]);

  useEffect(() => {
    if (user && fetchingUserLoyalties) {
      fetchExternalLoyalties()
        .then((loyalty) => {
          dispatch({ type: 'setLoyalties', externalLoyalties: loyalty?.data?.data });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          dispatch({ type: 'setFetchingLoyalties', fetching: false });
        });
    }
  }, [user, fetchingUserLoyalties]);

  useEffect(() => {
    if (user && fetchingLoyaltyLevels) {
      getLoyaltyLevels()
        .then((loyalty) => {
          dispatch({ type: 'setLoyaltyLevels', loyaltyLevels: loyalty?.data?.levels });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          dispatch({ type: 'setFetchingLoyaltyLevels', fetching: false });
        });
    }
  }, [user, fetchingLoyaltyLevels]);

  useEffect(() => {
    if (!language?.language) {
      dispatch({
        type: 'setUserLanguage',
        language: { language: Intl.DateTimeFormat().resolvedOptions().locale ?? 'en-US' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    fetchingUserData,
    fetchingUserCards,
    fetchingUserLoyalties,
    user,
    cards,
    externalLoyalties,
    userDispatch: dispatch,
    isAuthenticated,
    getPriceFormat,
    language,
    handleLogout,
    loyaltyLevels,
    fetchingLoyaltyLevels,
  };

  return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>;
};
