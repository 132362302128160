import { Banner } from '@app/components/Banner';
import { CallToAction } from '@app/components/CallToAction';
import { CategoriesCarousel } from '@app/components/CategoriesCarousel';
import { CarouselSingleItemProps } from '@app/components/CategoriesCarousel/CategoriesCarousel';
import { RegisterNowBanner } from '@app/components/RegisterNowBanner';
import { useSearch } from '@app/context/search';
import { getAllowedParams } from '@app/context/search/utils';
import { useUser } from '@app/context/user';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Dashboard = () => {
  const placesLibrary = useMapsLibrary('places');
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const { isAuthenticated } = useUser();
  const { searchDispatch } = useSearch();
  const navigate = useNavigate();

  const suggestedCitiesClick = (item?: CarouselSingleItemProps) => {
    if (item) {
      placesService?.getDetails({ placeId: item.id as string }, (place) => {
        searchDispatch({
          type: 'updateSearchParams',
          updateSearchParams: {
            adults: 1,
            children: 0,
            placeID: item.id as string,
            inDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
            outDate: format(addDays(new Date(), 4), 'yyyy-MM-dd'),
            location: place?.formatted_address ?? item.label,
            searchLatitude: place?.geometry?.location?.lat(),
            searchLongitude: place?.geometry?.location?.lng(),
          },
        });

        navigate(
          `/results?${getAllowedParams(
            {
              adults: 1,
              children: 0,
              placeID: item.id as string,
              inDate: '',
              outDate: '',
              location: place?.formatted_address ?? item.label,
              searchLatitude: place?.geometry?.location?.lat(),
              searchLongitude: place?.geometry?.location?.lng(),
            },
            true,
            true,
          )}`,
        );
      });
    }
  };

  useEffect(() => {
    if (!placesLibrary) return;

    setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));

    return () => setPlacesService(null);
  }, [placesLibrary]);

  return (
    <div className="pb-10">
      <Banner clearFields />
      {!isAuthenticated && (
        <div className="my-10">
          <RegisterNowBanner />
        </div>
      )}
      <div className="my-10 relative">
        <CategoriesCarousel
          title="Suggested Cities"
          handleChoice={suggestedCitiesClick}
          countries={[
            { id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI', image: '/images/uk.jpeg', name: 'London' },
            { id: 'ChIJOwg_06VPwokRYv534QaPC8g', image: '/images/us.jpeg', name: 'New York' },
            { id: 'ChIJw0rXGxGKJRMRAIE4sppPCQM', image: '/images/italy.jpeg', name: 'Rome' },
            { id: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ', image: '/images/germany.jpeg', name: 'Berlin' },
            { id: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ', image: '/images/france.jpeg', name: 'Paris' },
            { id: 'ChIJKcEGZna4lxIRwOzSAv', image: '/images/spain.jpeg', name: 'Mallorca' },
            { id: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM', image: '/images/australia.jpg', name: 'Sydney' },
          ]}
        />
      </div>
      <div className="my-10">
        <CallToAction
          title="Win a $100 Delta Air Lines Gift Card!"
          image="/images/dlpromo.jpeg"
          text={
            <p>
              Register and book to earn entries* for a chance to win in our Delta Air Lines™ Gift Card giveaway!
              <br />
              Hurry! Enter by Nov. 30th, 2024!
            </p>
          }
          actionText="Register"
          action={() => {
            navigate('/promoRegistration?promoCode=DORDALGC2409');
          }}
        />
      </div>
    </div>
  );
};
