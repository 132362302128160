import { useQuery } from '@tanstack/react-query';
import { parseRateKey } from '../ResultDetails/utils';
import { getHotelRates, postBooking } from '@app/services/booking';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@app/context/user';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useBooking } from '@app/context/booking';
import { useTravellers } from '@app/context/travellers';
import { getFullNameFromFields, getHotelImageFromCategory, getUserBookingSelection } from '@app/utils/utils';
import { HotelRoomSelectionInterface } from '@app/models/search';
import { HotelImageCategories } from '@app/consts/enums';
import { bookingValidation } from '@app/utils/validations';
import { postPredictPoints } from '@app/services/loyalty';
import { useSearch } from '@app/context/search';
import { toast } from 'react-toastify';

interface OptionValueInterface {
  value: {
    givenName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    passengerCode: string;
  };
  label: string;
}
[];

export const useBookingPage = () => {
  const [searchParams] = useSearchParams();
  const { user, cards, getPriceFormat, externalLoyalties } = useUser();
  const { travellers } = useTravellers();
  const { searchParams: searchContext } = useSearch();
  const [availableTravellers, setAvailableTravellers] = useState<OptionValueInterface[]>([]);
  const [bookingError, setBookingError] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [userSelection, setUserSelection] = useState<HotelRoomSelectionInterface | undefined>();
  const [hotelImage, setHotelImage] = useState<string | undefined>();
  const [pointsPrediction, setPointsPrediction] = useState<number | undefined>();
  const { currentBooking, bookingsDispatch } = useBooking();
  const navigate = useNavigate();
  const formHook = useForm({ resolver: bookingValidation });
  const formHookTravellers = useForm();
  const rateKey = searchParams?.get('rateKey');
  const { data: rateDetails, isFetching } = useQuery({
    queryKey: ['hotel-details'],
    queryFn: () =>
      getHotelRates({ sourceRateCode: parseRateKey(rateKey ?? '') })
        .then((res) => res.data)
        .catch((err) => {
          toast.error(
            err?.response?.data?.error ??
              err?.data?.error ??
              'Error while trying to retrieve the hotel information or rates.',
          );
          toast.clearWaitingQueue();
        }),
    enabled: Boolean(rateKey),
    retry: false,
  });

  const mapTravellers = () => {
    const listOfTravellers = [];
    if (user) {
      listOfTravellers.push({
        value: {
          givenName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          passengerCode: 'ADT',
          phoneNumber: user.phoneNumber,
        },
        label: 'Me',
      });
    }
    if (travellers.length) {
      const availableCompanions = travellers.map((traveller) => ({
        value: {
          givenName: traveller.firstName,
          lastName: traveller.lastName,
          email: traveller.email,
          passengerCode: 'ADT',
          phoneNumber: traveller.phoneNumber,
        },
        label: getFullNameFromFields(traveller as unknown as { firstName: string; lastName: string }),
      }));
      listOfTravellers.push(...availableCompanions);
    }

    return listOfTravellers;
  };

  const userOption = formHookTravellers.watch('traveller');

  const completeBooking = (values: FieldValues) => {
    setBookingError(false);
    setIsBooking(true);
    const externalProvider = externalLoyalties.find(
      (loyalty) => loyalty.providerCode === values?.externalProvider?.value,
    );
    postBooking({
      cardId: values?.cardId,
      email: values?.email,
      sourceRateCode: parseRateKey(searchParams.get('rateKey') ?? ''),
      phoneNumber: values?.phone,
      travelers: [{ givenName: values.firstName, surname: values.lastName, passengerCode: 'ADT' }],
      ...(externalProvider && {
        loyaltyPrograms: {
          programNumber: externalProvider.providerAccountValue,
          supplierCode: externalProvider.providerCode,
        },
      }),
    })
      .then((res) => {
        bookingsDispatch({ type: 'setFetching', fetching: true });
        navigate(`/my-trips/${res?.data?.orderId}/${res?.data?.orderUUID}?status=success`);
      })
      .catch(() => setBookingError(true))
      .finally(() => setIsBooking(false));
  };

  useEffect(() => {
    if (user) {
      formHook.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (userOption) {
      formHook.reset({
        firstName: userOption?.value?.givenName,
        lastName: userOption?.value?.lastName,
        email: userOption?.value?.email,
        phone: userOption?.value?.phoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOption]);

  useEffect(() => {
    if (userSelection && externalLoyalties) {
      formHook.reset({
        ...formHook.getValues(),
        externalProvider:
          (externalLoyalties.find((loyalty) => loyalty.providerCode === userSelection?.chainCode)?.providerCode as {
            value?: string | undefined;
            label?: string | undefined;
          }) ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelection, externalLoyalties]);

  useEffect(() => {
    if (!userSelection && currentBooking && rateDetails) {
      const selectionDetails = getUserBookingSelection(currentBooking, rateDetails);
      const exteriorImage = getHotelImageFromCategory(rateDetails?.media, HotelImageCategories.EXTERIOR, 'MEDIUM');

      setUserSelection(selectionDetails);
      if (exteriorImage && exteriorImage.total > 0) {
        setHotelImage(exteriorImage?.images[0].Url);
      }

      if (selectionDetails) {
        postPredictPoints({
          checkInDate: currentBooking?.inDate ?? '',
          checkOutDate: currentBooking?.outDate ?? '',
          sourceRateCode: parseRateKey(rateKey ?? ''),
        })
          .then((res) => {
            setPointsPrediction(res?.data?.points ?? 0);
          })
          .catch((e) => console.error(e));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBooking, rateDetails]);

  useEffect(() => {
    const available = mapTravellers();
    setAvailableTravellers(available);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travellers]);

  useEffect(() => {
    if (!cards.find((card) => card.cardId === formHook.watch('cardId'))) {
      if (cards.length > 0) {
        formHook.resetField('cardId', { defaultValue: cards[0].cardId });
      } else {
        formHook.setValue('cardId', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  if (!rateKey) {
    navigate('/');
  }

  return {
    user,
    travellers,
    availableTravellers,
    getPriceFormat,
    currentBooking,
    rateDetails,
    isFetching,
    formHook,
    formHookTravellers,
    completeBooking,
    userSelection,
    hotelImage,
    isBooking,
    bookingError,
    searchContext,
    pointsPrediction,
    externalLoyalties,
  };
};
